import { useEffect, useState } from "react";
import { RiMenu3Line } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles";
import { languageOptions } from "../utils/utils";
import { FaMoon, FaDesktop } from "react-icons/fa";
import { FiSun } from "react-icons/fi";
const Navbar = ({ theme, setTheme }) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [lag, setLang] = useState(i18next.language);

  const navLinks = [
    {
      id: "home",
      title: t("Home"),
      link: "",
    },
    {
      id: "features",
      title: t("Features"),
      link: "https://find-and-replace-text-online.leonineinfo.com/",
    },
  ];

  const options = [
    {
      icon: <FiSun />,
      text: "light",
      name: t("light"),
    },
    {
      icon: <FaMoon />,
      text: "dark",
      name: t("dark"),
    },
    {
      icon: <FaDesktop />,
      text: "system",
      name: t("system"),
    },
  ];

  const handleOpt = (opt) => {
    setTheme(opt);
    setToggle(false);
    setOpen(false);
  };

  const handleTogglesm = () => {
    setOpen(false);
    setToggle((pre) => !pre);
  };

  useEffect(() => {
    setLang(i18next.language);
  }, [i18next.language]);

  return (
    <>
      <nav className='w-full flex py-6 justify-between items-center'>
        <div className='w-[200px] h-[32px] flex items-center cursor-pointer'>
          <a href={`${i18next.language}`}>
            <h2
              className={`hidden sm:block font-poppins font-bold text-[20px]
                ${theme == "light" ? "light-text-gradient" : theme === "dark" ? "text-gradient" : "light-text-gradient"}
                `}
            >
              {t("sitename")}
            </h2>
            <h2
              className={`block sm:hidden font-poppins font-bold text-[20px]
                ${theme == "light" ? "light-text-gradient" : theme === "dark" ? "text-gradient" : "light-text-gradient"}
                `}
            >
              {t("sitenamemobile")}
            </h2>
          </a>
        </div>
        <ul className='list-none sm:flex hidden justify-end items-center flex-1 '>
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`font-poppins font-medium cursor-pointer text-[16px] dark:text-white text-slate-800 mr-10 dark:font-normal hover:text-stone-950 dark:hover:text-cyan-400 transition-all duration-300 ease-in-out`}
            >
              <Link to={`${nav.link}`} target="_blank">{nav.title}</Link>
            </li>
          ))}
          <span
            onClick={() => setOpen((pre) => !pre)}
            className={`flex items-center font-poppins  mr-5 font-normal cursor-pointer text-[16px] dark:text-sky-400 text-slate-800`}
          >
            {theme === "light" ? <FiSun /> : theme === "dark" ? <FaMoon /> : <FaDesktop />}
          </span>

          <div
            className={`${open ? "flex" : "hidden"}
                p-2 bg-black-gradient absolute top-20 right-20 mx-4
                my-0 min-w-[100px] rounded-xl z-[4]`}
          >
            <ul className='list-none flex flex-col items-start flex-1'>
              {options.map((opt) => (
                <li
                  key={opt.text}
                  onClick={() => handleOpt(opt.text)}
                  className={`${styles.flexCenter} gap-2 font-poppins font-normal cursor-pointer text-[16px] 
                                    ${theme == opt.text ? "text-sky-600" : "text-gray-100"} 
                                    `}
                >
                  {opt.icon}
                  {opt.name}
                </li>
              ))}
            </ul>
          </div>
         <select
            value={lag}
            aria-label='Select Language'
            onChange={(e) => {
              setLang(e.target.value);
              i18next.changeLanguage(e.target.value);
              localStorage.setItem("slang",e.target.value)
              navigate(`/${e.target.value}`, { replace: true });
            }}
            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none'
          >
            {languageOptions.map((option) => (
              <option key={option.code} value={option.code}>
                {option.name}
              </option>
            ))}
          </select>
        </ul>
        <div className='sm:hidden flex justify-end items-center'>
          <span
            onClick={() => setOpen((pre) => !pre)}
            className={`flex items-center font-poppins font-normal cursor-pointer text-[16px] mr-3 dark:text-white text-black-100`}
          >
            {theme === "light" ? <FiSun /> : theme === "dark" ? <FaMoon /> : <FaDesktop />}
          </span>
          <select
            aria-label='Select Language'
            value={lag}
            onChange={(e) => {
              setLang(e.target.value);
              i18next.changeLanguage(e.target.value);
              navigate(`/${e.target.value}`, { replace: true });
            }}
            className='cursor-pointer mr-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none'
          >
            {languageOptions.map((option) => (
              <option key={option.code} value={option.code}>
                {option.name}
              </option>
            ))}
          </select>
          <div
            className={`${open ? "flex" : "hidden"}
                p-2 bg-black-gradient absolute top-20 right-20 mx-4
                my-0 min-w-[100px] rounded-xl z-[4]`}
          >
            <ul className='list-none flex flex-col items-start'>
              {options.map((opt) => (
                <li
                  key={opt.text}
                  onClick={() => handleOpt(opt.text)}
                  className={`${styles.flexCenter} gap-2 font-poppins font-normal cursor-pointer text-[16px] 
                                    ${theme == opt.text ? "text-sky-600" : "text-gray-100"} 
                                    `}
                >
                  {opt.icon}
                  {opt.name}
                </li>
              ))}
            </ul>
          </div>
          <div>
            {toggle ? (
              <MdClose className={`cursor-pointer object-contain dark:text-sky-400 text-Slate-800`} onClick={handleTogglesm} alt='close' />
            ) : (
              <RiMenu3Line className={`cursor-pointer object-contain dark:text-sky-400 text-Slate-800`} onClick={handleTogglesm} alt='menu' />
            )}
          </div>
          <div
            className={`${toggle ? "flex" : "hidden"}
                p-6 bg-black-gradient absolute top-20 right-0 mx-4
                my-2 min-w-[140px] rounded-xl sidebar z-[4]`}
          >
            <ul className='list-none flex flex-col justify-end items-center flex-1'>
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-normal cursor-pointer text-[16px] dark:hover:text-cyan-400 transition-all duration-300 ease-in-out text-white hover:text-slate-400 ${
                    index == navLinks.length - 1 ? "mb-0" : "mb-4"
                  }`}
                >
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
