import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import App from "../App";
import i18next from "i18next";
import { supportedLanguages } from "../utils/utils";

function Router() {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    const languageFromURL = currentPath.substring(1);

    const isInitial = localStorage.getItem("initial") !== "true";
    const browserLanguage = navigator.language.split("-")[0];
    const slang = localStorage.getItem("slang");

    if (supportedLanguages.includes(slang) && slang) {
      localStorage.setItem("initial", "true");
      navigate(`/${slang}`, { replace: true });
      i18next.changeLanguage(slang);
    } else {
      if (supportedLanguages.includes(browserLanguage) && isInitial) {
        localStorage.setItem("initial", "true");
        navigate(`/${browserLanguage}`, { replace: true });
        i18next.changeLanguage(browserLanguage);
      } else if (languageFromURL && supportedLanguages.includes(languageFromURL)) {
        i18next.changeLanguage(languageFromURL);

      } else if (currentPath !== "/sitemap.xml") {
        navigate(`/${browserLanguage}`, { replace: true });
        i18next.changeLanguage(browserLanguage);
      }
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path={`/${i18next.language}`} element={<App />} />
      <Route path='*' element={<App />} />
    </Routes>
  );
}

export default Router;
