import styles from "../styles.js";
import "../index.css";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { MdSettings } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
const Toolarea = ({ theme, advancedOptions, setAdvancedOptions }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState(16);
  const wordCount = text.split(/\s+/).filter(Boolean).length;
  const charCount = text.length;
  const lines = text.split('\n');
  const lineCount = lines.length;
  const [isCopied, setIsCopied] = useState(false);
  const [selectedCase, setSelectedCase] = useState({
    sentenceCase: false,
    lowerCase: false,
    upperCase: false,
    capitalizedCase: false,
    alternatingCase: false,
    titleCase: false,
  });

  const cases = [
    { name: "Sentence_case", key: "sentenceCase" },
    { name: "Lower_case", key: "lowerCase" },
    { name: "Upper_case", key: "upperCase" },
    { name: "Capitalized_case", key: "capitalizedCase" },
    { name: "Alternating_case", key: "alternatingCase" },
    { name: "Title_case", key: "titleCase" },
  ];

  const handlecase = (activekey) => {
    if (text.trim().length > 0) {
      const newSelectedCase = { ...selectedCase };
      Object.keys(newSelectedCase).forEach((key) => {
        newSelectedCase[key] = key === activekey;
      });
      setSelectedCase(newSelectedCase);
      handleCaseButtonClick(activekey);
    }
  };

  const handleCopyToClipboard = () => {
    if (text.length !== 0) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  };

  const handleReset = () => {
    setText("");
    setSelectedCase(Object.fromEntries(Object.keys(selectedCase).map((key) => [key, false])));
  };

  const handleTextareaChange = (e) => {
    setText(e.target.value);
    setSelectedCase(Object.fromEntries(Object.keys(selectedCase).map((key) => [key, false])));
  };

  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };

  const defaultFontSize = 16;

  const handleResetFontSize = () => {
    setFontSize(defaultFontSize);
  };

  const handleCaseButtonClick = (caseName) => {
    const lines = text.split('\n');

    switch (caseName) {
      case "sentenceCase":
        const sentenceCaseLines = lines.map(line => {
          return line.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, c => c.toUpperCase());
        });
        setText(sentenceCaseLines.join('\n'));
        break;
      case "lowerCase":
        const lowerCaseLines = lines.map(line => line.toLowerCase());
        setText(lowerCaseLines.join('\n'));
        break;
      case "upperCase":
        const upperCaseLines = lines.map(line => line.toUpperCase());
        setText(upperCaseLines.join('\n'));
        break;
      case "capitalizedCase":
        const capitalizedCaseLines = lines.map(line => {
          return line
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
        });
        setText(capitalizedCaseLines.join('\n'));
        break;
      case "alternatingCase":
        const alternatingCaseLines = lines.map(line => {
          return line
            .split("")
            .map((char, index) => (index % 2 === 0 ? char.toUpperCase() : char.toLowerCase()))
            .join("");
        });
        setText(alternatingCaseLines.join('\n'));
        break;
      case "titleCase":
        const titleCaseLines = lines.map(line => {
          return line
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
        });
        setText(titleCaseLines.join('\n'));
        break;
      default:
        break;
    }
  };


  return (
    <>
      <section>
        <div className={`flex items-end flex-col xl:px-0 sm:px-16 px-6`}>
          <input
            aria-label='Font Size'
            type='range'
            id='fontSizeSlider'
            min='12'
            max='28'
            value={fontSize}
            className={`mt-2 common-color cursor-pointer`}
            onChange={handleFontSizeChange}
          />
          <button
            type='button'
            onClick={handleResetFontSize}
            className='mt-2 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br  shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-normal rounded-lg text-sm px-2 py-2 text-center mr-1 mb-1 dark:bg-gradient-to-r dark:from-cyan-400 dark:via-cyan-500 dark:to-cyan-600 dark:hover:bg-gradient-to-br dark:text-white'
          >
            {t("tool.Reset_Font_Size")}
          </button>
        </div>
      </section>
      <section id='toolbar' className={`ol mt-5`}>
        <div className={`flex items-start flex-col xl:px-0 sm:px-16 px-6 h-3/5 w-100 mt-3`}>
          <textarea
            onChange={handleTextareaChange}
            spellCheck='false'
            value={text}
            style={{
              fontSize: `${fontSize}px`,
              lineHeight: `${fontSize}>=20 ? '2rem':'1'`,
              minHeight: "380px",
              maxHeight: "381px",
            }}
            placeholder={t("tool.Paste_your_text_here")}
            id='message'
            rows='15'
            cols='100'
            className='resize-none font-poppins text-base font-normal block p-2.5 w-full text-gray-900 bg-neutral-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
          ></textarea>
          <p className='block mt-2 font-poppins text-sm text-gray-900 dark:text-white mb-5'>
            {t("tool.Words_Count")} <span className='font-poppins font-medium text-base'>{wordCount}</span> &nbsp; {t("tool.Characters_Count")}{" "}
            <span className='font-poppins text-base font-medium'>{charCount}</span> &nbsp; {t("tool.Lines_Count")}{" "}
            <span className='font-poppins text-base font-medium'>{ text.length >0 ? lineCount:"0"}</span>
          </p>
          <div className={`flex flex-row gap-5 w-full justify-between items-center flex-wrap pb-3`}>
            <div>
              <CopyToClipboard text={text} onCopy={handleCopyToClipboard}>
                <button
                  type='button'
                  className='text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-normal rounded-lg text-sm px-2 py-2 text-center mr-1 mb-1 dark:bg-gradient-to-r dark:from-cyan-400 dark:via-cyan-500 dark:to-cyan-600 dark:hover-bg-gradient-to-br dark:text-white'
                >
                  {isCopied ? t("tool.Copied") : t("tool.Copy_to_Clipboard")}
                </button>
              </CopyToClipboard>
            </div>
            <div>
              <button
                type='button'
                onClick={handleReset}
                className='text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-2 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
              >
                {t("tool.Reset")}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='hidden sm:block'>
        <div
          className={`flex flex-col gap-2 justify-evenly xl:justify-around w-screen xl:px-0 sm:px-16 px-6 mt-5 fixed bottom-0 items-center py-3 z-[5] ${theme === "light" ? "bg-slate-300" : theme === "dark" ? "bg-discount-gradient " : "bg-slate-300 "
            }`}
        >
          <div className='flex flex-col sm:flex-row justify-center gap-x-5 gap-y-2 flex-wrap w-screen xl:px-0 sm:px-16 px-6'>
            {cases.map((item) => (
              <button
                key={item.key}
                type='button'
                onClick={() => handlecase(item.key)}
                className={`text-gray-900 hover:text-white font-medium rounded-lg text-sm px-2 py-2 text-center mr-2 my-1 
        ${selectedCase[item.key] && "dark:text-white dark:bg-black bg-gray-900 text-white dark:border-amber-600 border-lime-600"}
        border border-gray-800 hover:bg-gray-900
        dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-black`}
              >
                {t(`tool.${item.name}`)}
              </button>
            ))}
          </div>
        </div>
      </section>

      {/* mobile */}
      <section className='block sm:hidden'>
        <div
          className={`flex flex-col gap-2 justify-evenly xl:justify-around w-screen xl:px-0 sm:px-16 px-6 mt-5 fixed bottom-0 items-center py-3 z-[5] ${theme === "light" ? "bg-slate-300 " : theme === "dark" ? "bg-discount-gradient " : "bg-slate-300 "
            }`}
        >
          {!advancedOptions && (
            <span
              onClick={() => setAdvancedOptions(true)}
              className={` ${styles.flexCenter} gap-2 font-poppins cursor-pointer text-[14px] dark:text-white text-gray-900 transition ease-in-out duration-300 `}
            >
              <MdSettings
                className={`${styles.flexCenter} hover:rotate-45 w-[20px] h-[20px] font-medium object-contain text-sm dark:text-sky-400 text-gray-900`}
                alt='settings'
              />
              {t("tool.Options")}
            </span>
          )}

          {advancedOptions && (
            <div
              onClick={() => setAdvancedOptions(false)}
              className={`flex flex-row items-center ${theme === "light" ? "bg-slate-500" : theme === "dark" ? "bg-discount-gradient " : "bg-slate-500"
                } settings-container-mb p-2 pr-3 pl-3 rounded `}
            >
              <AiOutlineClose
                className={`${styles.flexCenter} w-[20px] h-[20px] object-contain dark:text-sky-400 text-slate-200 transition ease-in-out duration-300 hover:scale-110`}
                alt='settings'
              />
            </div>
          )}
          {advancedOptions && (
            <div className='flex flex-row w-screen justify-center gap-x-5 gap-y-2 flex-wrap xl:px-0 sm:px-16 px-6'>
              {cases.map((item) => (
                <button
                  key={item.key}
                  type='button'
                  onClick={() => handlecase(item.key)}
                  className={`text-gray-900 hover:text-white font-medium rounded-lg text-sm px-2 py-2 text-center mr-2 my-1
        ${selectedCase[item.key] && "dark:text-white dark:bg-black bg-gray-900 text-white dark:border-amber-600 border-lime-600"}
        border border-gray-800 hover:bg-gray-900
        dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-black`}
                >
                  {t(`tool.${item.name}`)}
                </button>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Toolarea;
